import * as React from "react"
import { Helmet } from "react-helmet"
import { Layout, Review } from "../components"
import LogoColorful from "../assets/papelzinho-logo-colorful.png"

const reviews: { title: string; body: string }[] = [
  {
    title: "TOP DEMAIS!!!",
    body: "Muito legal, muito fácil de fazer, basta inserir os nomes já com opção de presentes para cada amigo secreto, inserir o e-mail e pronto! Sorteia nos e-mails e nenhum vê o do outro, legal demais! Super recomendo! Parabéns ao desenvolvedor!",
  },
  {
    title: "MUITOOO bom!!!",
    body: "Muito bom o aplicativo!! Super recomendo!! O email n demora a chegar, é super rápido e prático e n tem propaganda!! Recomendo demais, n tenho nada a reclamar sobre o app!! pena q n tem como dar 6 estrelas.",
  },
  {
    title: "Excelente",
    body: "Muito fácil, com muitas opções. É fantástico estarei sempre usando. O melhor que não precisa estar todos reunidos em um lugar e nem tem o risco de tirar o próprio nome. Nem mesmo fazer marmelada. Uma dica é colocar a função de envio por SMS.",
  },
  {
    title: "Ótimo",
    body: "Tive um problema recentemente, e entrei em contato com eles através do Facebook e me responderam rápido e resolveram o meu problema em questão de minutos. Estão de parabéns pelo app e pelo atendimento.",
  },
]

const IndexPage: React.FC = () => {
  return (
    <>
      <Layout noHeader={true}>
        <Helmet>
          <title>Papelzinho teste 2 ™</title>
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8913194362956705"
            crossOrigin="anonymous"
          ></script>
        </Helmet>
        <header className="p-5">
          <img
            src={LogoColorful}
            alt="Papelzinho logo"
            width={150}
            height={27}
          />
        </header>
        <section className="gradient-bg px-5 py-5 text-light">
          <h1 className="mb-0 font-weight-bold">
            A solução pra quem está cansado de escrever e dobrar pedacinhos de
            papel para o sorteio de amigo secreto.
          </h1>
        </section>

        <section className="px-5 pt-5 text-secondary">
          <div>
            <p>
              O Papelzinho é o app perfeito para realizar seus sorteios de amigo
              secreto com praticidade e sem papelzinho de verdade{" "}
              <span role="img" aria-label="LOL">
                😂
              </span>
            </p>

            <p>
              Crie quantos grupos quiser, assim dá pra fazer um amigo secreto
              diferente com cada galera! O número de participantes é infinito e
              todos podem colocar até 3 opções de presentes.
            </p>

            <p>
              <span className="font-weight-bolder">Como funciona</span>
              <br />O sorteio é realizado no próprio app e todos os integrantes
              receberão um e-mail com link direcionado para a revelação do seu
              amigo oculto. É nesse mesmo site que cada um pode escrever suas
              preferências de presente.
            </p>

            <p>Boa diversão!</p>

            <p>
              Pintou alguma dúvida ou tem sugestões? Fala com a gente pelo{" "}
              <a
                className="email font-weight-bolder"
                href="https://api.whatsapp.com/message/4ZGE273VAQXKP1?autoload=1&app_absent=0"
              >
                Whatsapp.
              </a>
            </p>
          </div>
        </section>

        <section className="px-5 pt-4 text-secondary">
          <div className="row">
            <div className="col">
              <h3 className="text-dark">Avaliações e opiniões</h3>
            </div>
          </div>

          <div className="row row-cols-1 row-cols-md-2 mt-3">
            {reviews.map((review, index) => (
              <div className="col" key={index}>
                <Review title={review.title} body={review.body} />
              </div>
            ))}
          </div>
        </section>
      </Layout>
    </>
  )
}

export default IndexPage
